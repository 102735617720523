.calculations-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.calculations-header {
  align-items: center;
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 40px 0 40px; /* top right bottom left */
}