.input-row-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 20px;
  margin-bottom: 10px;
  align-items: center;
}

.input-row-text {
  display: flex;
  font-weight: 600;
}
