@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

@font-face {
  font-family: 'ProximaNovaRegular';
  src: url('fonts/ProximaNova-Regular.otf');
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('fonts/ProximaNova-Bold.otf');
}

a {
  text-decoration: none;
  margin-left: 4px;
  color: black;
}

.App {
  display: flex;
  flex: 1;
  /* font-family: "Roboto", sans-serif; */
  font-family: "ProximaNovaRegular";
  font-weight: 400;
  font-size: 24px;
  flex-direction: column;
}

input:focus {
  outline: none;
}

body {
  background-color: #F9F9F9;
}