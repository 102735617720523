.contact-details-container {
  display: flex;
  margin-bottom: 50px;
  margin-top: 32px;
  justify-content: space-between;
  padding: 0 40px 0 40px;
}

.contact-details-container .input-field-container {
  flex: 1;
}

.contact-details-left-content {
  display: flex;
  flex-direction: column;
}

.contact-details-name-container {
  display: flex;
  margin-bottom: 18px;
  justify-content: space-between;
}

.contact-details-disclaimer-text {
  font-size: 12px;
  margin-top: 16px;
  width: 75%;
}

.contact-details-button {
  display: flex;
  align-items: flex-end;
  margin-left: 100px;
}
