@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ProximaNovaRegular';
  src: url(/static/media/ProximaNova-Regular.ea03f826.otf);
}

@font-face {
  font-family: 'ProximaNovaBold';
  src: url(/static/media/ProximaNova-Bold.fcaab252.otf);
}

a {
  text-decoration: none;
  margin-left: 4px;
  color: black;
}

.App {
  display: flex;
  flex: 1 1;
  /* font-family: "Roboto", sans-serif; */
  font-family: "ProximaNovaRegular";
  font-weight: 400;
  font-size: 24px;
  flex-direction: column;
}

input:focus {
  outline: none;
}

body {
  background-color: #F9F9F9;
}
.divider{width:100%;height:1px;background-color:#a4a4b1;margin-top:12px}
.accordion-container{display:flex;flex:1 1;padding:20px 40px 0 40px;flex-direction:column}.accordion-header{display:flex;justify-content:space-between;align-items:center;margin-bottom:20px}.accordion-header-text{color:#1b1c3c;font-size:32px;font-weight:600}.accordion-control-button{cursor:pointer;margin-right:22px;text-align:center}.accordion-control-button-indicator{color:#3d5afe;font-size:32px;font-weight:600}.accordion-control-button-indicator-collapsed{color:#3d5afe;font-size:20px;margin-top:-4px;line-height:.5;font-weight:600}.accordion-subheading-text{font-size:14px;padding-top:12px;max-width:70%}
.checkbox-container{display:flex;font-size:12px;margin-top:12px;align-items:center}.checkbox{width:18px;height:18px;border-radius:2px;background-color:#fcc02b;cursor:pointer;border:1px solid #fcc02b}.checkbox-text{font-weight:600;margin-left:6px}
.input-field-container{display:flex;flex-direction:column}.input-field-values{background-color:#fff;border:1px solid #d3d3d3;border-radius:10px;padding:8px;display:flex;min-width:160px;margin-right:12px;padding-right:12px;align-items:center}.input-field{border:0;flex:1 1;font-size:24px;text-align:right}.input-field-suffix-container{width:20px}.input-field-validation-text{color:red;padding-top:4px;font-size:14px}
.rounded-button{padding:20px;text-align:center;border:3px solid #3d5afe;border-radius:18px;color:#3d5afe;font-size:20px;font-weight:600;cursor:pointer;width:150px}.rounded-button:hover{background-color:#3d5afe;color:#fff}
.contact-details-container{display:flex;margin-bottom:50px;margin-top:32px;justify-content:space-between;padding:0 40px 0 40px}.contact-details-container .input-field-container{flex:1 1}.contact-details-left-content{display:flex;flex-direction:column}.contact-details-name-container{display:flex;margin-bottom:18px;justify-content:space-between}.contact-details-disclaimer-text{font-size:12px;margin-top:16px;width:75%}.contact-details-button{display:flex;align-items:flex-end;margin-left:100px}
.input-row-container{display:flex;justify-content:space-between;flex:1 1;padding-top:20px;margin-bottom:10px;align-items:center}.input-row-text{display:flex;font-weight:600}
.tooltip-icon{background-color:#c4c4c4;border-radius:14px;width:14px;height:14px;font-size:11px;text-align:center;display:flex;align-items:center;justify-content:center;cursor:pointer}
.calculations-container{display:flex;flex-direction:column;flex:1 1}.calculations-header{align-items:center;background-color:#fff;display:flex;flex:1 1;flex-direction:row;justify-content:space-between;padding:10px 40px 0 40px}
.currency-selector-container{display:flex;justify-content:flex-end;padding-right:40px}.currency-option{font-size:32px;font-weight:600;padding:8px;cursor:pointer}.currency-selector-container>small{color:#1b1c3c;font-size:16px;font-weight:600;margin:15px 10px 0 0}
.yoyopro-logo{height:35px}
.impact-row{display:flex;margin-top:12px;margin-bottom:12px}.impact-row-header-text{flex:1 1;font-size:36px;font-weight:600;padding-bottom:16px}.impact-row-heading{flex:1 1;font-size:24px}.impact-row-values-container{display:flex;flex:1 1}.impact-row-input-field{flex:2 1}.impact-row-spacer{flex:1 1}.impact-row-text{font-weight:600}
.value-card-container{display:flex;flex-direction:row;padding:20px;padding-left:30px;padding-right:30px;color:#1b1c3c;border-radius:6px;flex-grow:1;margin-left:40px;min-height:200px;flex:1 1}.value-card-container>div{display:flex;flex-direction:column;justify-content:space-between}.value-card-heading-text{font-size:30px;flex-grow:1;width:400px;font-weight:600}.value-card-value-text{font-size:60px;font-weight:bold;padding-top:20px;padding-bottom:20px;display:flex}.value-card-icon{font-size:50px;margin-right:20px}.value-card-icon img{width:64px}
.summary-container{display:flex;flex-direction:column;flex:1 1;padding-top:40px}.summary-container-value-cards-row{display:flex;justify-content:space-between;margin-right:40px}.summary-content{display:flex;flex-direction:column;margin-top:60px;padding-left:40px;padding-right:40px}.summary-get-started-button{display:flex;margin-top:20px;margin-left:40px}.summary-disclaimer-text{font-size:10px;margin:40px;width:75%}
