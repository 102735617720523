@import "../../common/colours.scss";

.checkbox-container {
  display: flex;
  font-size: 12px;
  margin-top: 12px;
  align-items: center;
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: $background-yellow;
  cursor: pointer;
  border: 1px solid $background-yellow;
}

.checkbox-text {
  font-weight: 600;
  margin-left: 6px;
}
