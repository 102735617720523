.summary-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 40px;
}

.summary-container-value-cards-row {
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
}

.summary-content {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.summary-get-started-button {
  display: flex;
  margin-top: 20px;
  margin-left: 40px;
}

.summary-disclaimer-text {
  font-size: 10px;
  margin: 40px;
  width: 75%;
}