.currency-selector-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}

.currency-option {
  font-size: 32px;
  font-weight: 600;
  padding: 8px;
  cursor: pointer;
}

.currency-selector-container > small {
  color: #1B1C3C;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 10px 0 0 ;
}