@import "../../common/colours.scss";

.rounded-button {
  padding: 20px;
  text-align: center;
  border: 3px solid $base-blue;
  border-radius: 18px;
  color: $base-blue;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  width: 150px;
}

.rounded-button:hover {
  background-color: $base-blue;
  color: white;
}