.impact-row {
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
}

.impact-row-header-text {
  flex: 1;
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 16px;
}

.impact-row-heading {
  flex: 1;
  font-size: 24px;
}

.impact-row-values-container {
  display: flex;
  flex: 1;
}

.impact-row-input-field {
  flex: 2;
}

.impact-row-spacer {
  flex: 1;
}

.impact-row-text {
  font-weight: 600;
}