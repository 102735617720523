.input-field-container {
  display: flex;
  flex-direction: column;
}

.input-field-values {
  background-color: #FFF;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  min-width: 160px;
  margin-right: 12px;
  padding-right: 12px;
  align-items: center;
}

.input-field {
  border: 0;
  flex: 1;
  font-size: 24px;
  text-align: right;
}

.input-field-suffix-container {
  width: 20px;
}

.input-field-validation-text {
  color: red;
  padding-top: 4px;
  font-size: 14px;
}
