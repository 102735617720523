.accordion-container {
  display: flex;
  flex: 1;
  padding: 20px 40px 0 40px;
  flex-direction: column;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.accordion-header-text {
  color: #1B1C3C;
  font-size: 32px;
  font-weight: 600;
}

.accordion-control-button {
  cursor: pointer;
  margin-right: 22px;
  text-align: center;
}

.accordion-control-button-indicator {
  color: #3D5AFE;
  font-size: 32px;
  font-weight: 600;
}

.accordion-control-button-indicator-collapsed {
  color: #3D5AFE;
  font-size: 20px;
  margin-top: -4px;
  line-height: 0.5;
  font-weight: 600;
}

.accordion-subheading-text {
  font-size: 14px;
  padding-top: 12px;
  max-width: 70%;
}
