.value-card-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #1B1C3C;
  border-radius: 6px;
  flex-grow: 1;
  margin-left: 40px;
  min-height: 200px;
  flex: 1;
}

.value-card-container > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.value-card-heading-text {
  font-size: 30px;
  flex-grow: 1;
  width: 400px;
  font-weight: 600;
}

.value-card-value-text {
  font-size: 60px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.value-card-icon {
  font-size: 50px;
  margin-right: 20px;
}

.value-card-icon img {
  width: 64px;
}