@import "../../common/colours.scss";

.tooltip-icon {
  background-color: $background-grey;
  border-radius: 14px;
  width: 14px;
  height: 14px;
  font-size: 11px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
